@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');

:root{
  --blue: #007e9b;
  --black: #000;
  --grey: #ccc;
  --ultra-light-grey: #f3f3f3;
  --white: #FFF;
  --primary-font: "Montserrat", sans-serif;
  --primary-font-size: 18px;
}

/* General Styles */
* { margin: 0; padding: 0; box-sizing: border-box;}
html { font-family:  var(--primary-font); font-weight: 300; font-size: var(--primary-font-size);}
body { min-height: 100vh; color: var(--black);}
.App { min-height: 100vh; display: flex;}

aside { padding: 20px; flex: 0 0 20%;}
aside nav { display: flex; flex-direction: column;}
aside nav a { text-decoration: none; color: var(--black); line-height: 50px; font-size: 22px; background: var(--ultra-light-grey); padding-left: 20px}
aside nav a.active { text-decoration: none; color: var(--blue); border-right: 5px solid var(--blue);}
aside nav a.logout { color: var(--white); background: var(--black); border-right: none !important; margin-top: 20px;}
aside nav a.refresh { color: var(--white); background: var(--blue); border-right: none !important; margin-top: 20px;}
aside nav a:hover { color: var(--white); background: var(--grey);}
section { flex: 0 0 80%; padding: 20px;}
section.background { background-image: url('assets/drive_colorado_bg.jpg'); background-size: cover; flex: 0 0 80%; }
footer { position: absolute; bottom: 20px; left: 20px;}

/* Forms */
form { display: flex; flex-direction: column; justify-content: flex-start; flex-grow: 1; padding-bottom: 1rem; width: 70%;}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
button,
textarea { font-family: 'Fira Sans', sans-serif; font-size: 22px; padding: 0.25rem;}

form label { font-weight: 700; font-size: 18px; margin-bottom: 5px;}
form input:not([type=checkbox]) { height: 50px; margin-bottom: 10px;}
form input, form textarea { background: #f7f7f7; border: 1px solid #c4c4c4; border-radius: 0; box-shadow: none; color: #787878; font-weight: 300; width: 100%;}
form input:focus, form textarea:focus { background: var(--white); border: 1px solid #919191; box-shadow: none; outline: none;}
form input:focus-visible, form textarea:focus-visible { background: var(--white); border: 1px solid #919191; box-shadow: none; outline: none;}
form .inputWrapper { position: relative;}
form .valid, form .invalid { position: absolute; right: 0; top: 0; height: 50px; width: 50px; text-align: center; line-height: 57px;}
form input.error, form textarea.error { border: 1px solid var(--red);}
form span.required { color: var(--red);}
p.required { font-size: .8em; text-align: right; font-style: italic; width: 100%;}
p.required span { color: var(--red);}
form input#persist { width: 20px;}

form select { background: #f7f7f7; border: 1px solid #c4c4c4; border-radius: 0; width: 100%; height: 50px; line-height: 50px; color: #787878; font-weight: 300; font-size: 22px; font-family: var(--primary-font); padding-left: 10px;}

label, button { margin-top: 1rem;}
button { padding: 0.5rem; background: var(--blue); border: none; color: var(--white);}
button:hover:not(:disabled) { background: var(--black);}
button:disabled { opacity: .5; cursor: not-allowed;}

textarea { resize: vertical;}

/* Dashboard */
section.overview { flex: 0 0 80%; padding: 20px;}
section.passWrapper { display: flex; flex-wrap: wrap; padding: 20px 0 !important}
article.pass { display: flex; flex-direction: row; line-height: 50px; flex: 0 0 33%; flex-wrap: wrap; padding: 0 20px; cursor: pointer;}
article.pass:hover { background: var(--ultra-light-grey);}
article.pass span {}
article.pass span:before { content: ''; width: 20px; height: 20px; border-radius: 50%; margin-right: 10px; background: var(--grey); display: inline-block; position: relative; top: 3px;}
article.pass .status.open span:before { background: green;}
article.pass .status.closed span:before { background: red;}

/* Pass Overview */
.passOverviewWrapper {}
.passOverviewContainer { display: flex; margin: 20px 0; border: 1px solid var(--black); }
.passOverviewContainer div { flex: 0 0 25%; line-height: 100px; text-align: center; font-size: 1.5em;}
.passOverviewContainer div:not(:last-of-type) { border-right: 1px solid var(--black);}
.passOverviewContainer .status {}
.passOverviewContainer .statusOverride {}
.passOverviewContainer .chainLaw {}
.passOverviewContainer .tractionLaw {}

/* List & Keys */
.keys div { font-weight: bold;}
ul.list { list-style: none; font-size: 22px; line-height: 50px;}
ul.list li { display: flex; flex-direction: row; border-bottom: 1px solid var(--grey);}
ul.list li:hover:not(.keys) { background: var(--ultra-light-grey);}
ul.list li div.buttons { display: flex; flex-direction: row; justify-content: end; padding-right: 10px;}
ul.list li div.buttons div { cursor: pointer; margin-left: 10px; font-size: 1.25em;}
ul.list li div:first-of-type { padding-left: 10px;}
ul.list li div.delete { color: red;}
ul.list li div.archive { color: blue;}

/* Reports */
.reports div.type { flex: 0 0 10%; text-transform: uppercase;}
.reports div.category { flex: 0 0 10%; text-transform: uppercase;}
.reports div.report {flex: 0 0 40%; text-transform: capitalize;;}
.reports div.confirmed {flex: 0 0 10%;}
.reports div.opposed {flex: 0 0 10%;}
.reports div.date {flex: 0 0 15%;}
.reports div.buttons {flex: 0 0 5%;}

/* Users */
.users div.name { flex: 0 0 40%; text-transform: capitalize;}
.users div.lastLoggedIn {flex: 0 0 20%;}
.users div.role {flex: 0 0 20%;}
.users div.role span { background: var(--black); color: var(--white); padding: 5px 10px; border-radius: 20px; font-size: 16px !important;}
.users div.role.superAdmin span { background: red; color: var(--white);}
.users div.buttons {flex: 0 0 20%;}

/* Maintenance */
.maintenance div.passName { flex: 0 0 40%; text-transform: capitalize;}
.maintenance div.date {flex: 0 0 20%;}
.maintenance div.enabled {flex: 0 0 20%;}
.maintenance div.enabled span { background: var(--black); color: var(--white); padding: 5px 10px; border-radius: 20px; font-size: 16px !important;}
.maintenance div.enabled.true span { background: red; color: var(--white);}
.maintenance div.buttons {flex: 0 0 20%;}
.maintenance div.buttons {flex: 0 0 20%;}
.globalButtons button { margin-right: 10px;}
.maintenance div.buttons .maintenanceMode.enable { color: red;}


